@import 'settings/variable'; //themes' colors
//vendor...
@import './vendor';
//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding'; // styles of base elements
@import './base/typography';
@import "./base/spacing.scss"; // base styles of h1-h6, p, span
@import "./base/display.scss"; // display styles
//components...
@import './component/alert'; // components/Alert.js ('reactstrap')
@import './component/btn'; // Button from 'reactstrap'
@import './component/calendar'; // components/Calendar.js  ('react-big-calendar')
@import './component/card'; // Card, CardBody from 'reactstrap'
@import './component/carousel'; // components/carousel/* ('react-slick')
@import './component/catalog_item'; // components/catalog/CatalogItems.js
@import './component/chat'; // components/chat/*
@import './component/charts'; // components/charts/*
@import './component/check-box'; // components/form/Checkbox.js and other components with checkboxes
@import './component/collapse'; // components/Collapse.js ('reactstrap')
@import './component/color-picker'; // components/form/*ColorPicker.js ('react-color')
@import './component/date-picker'; // components/form/*Date*Picker.js ('react-datepicker')
@import './component/dropdown'; //
@import './component/dropzone'; // components/form/DropZone*.js ('react-dropzone')
@import './component/edit-table'; // components/table/EditableTable.js ('react-data-grid')
@import './component/form'; // <form> and all types of inputs
@import './component/gallery'; // components/Gallery.js
@import './component/grid'; // useless. using for grid presentation
@import './component/inbox'; // components/inbox/*
@import './component/list';
@import './component/map'; // GoogleMap from '@react-google-maps/api'
@import './component/material-form'; // for 'material-ui'
@import './component/modal'; // components/Modal.js ('reactstrap')
@import './component/notification'; // components/Notification.js ('reactstrap')
@import './component/pagination'; // components/Pagination.js ('reactstrap')
@import './component/panel'; // components/Panel.js
@import './component/popover'; // Popover from 'reactstrap'
@import './component/pricing-card';
@import './component/product-gallery'; // components/catalog/ProductGallery.js
@import './component/progress-bar'; // ProgressBar from 'reactstrap'
@import './component/project-member'; // components/ProjectMember.js
@import './component/radio-btn'; // components/form/RadioButton.js
@import './component/react-select';
@import './component/scroll';
@import './component/search-result'; // components/SearchResult.js
@import './component/select-options'; // <select> and <option>
@import './component/sidebar'; // components/sidebar/*
@import './component/slider'; // components/range_slider/* ('rc-slider')
@import './component/table'; // components/Table.js ('reactstrap')
@import './component/tabs'; // Nav, NavItem, NavLink, TabContent, TabPane from 'reactstrap'
@import './component/text-editor'; // components/text-editor/TextEditor.js ('react-draft-wysiwyg')
@import './component/time-picker'; // components/form/TimePicker.js ('react-times')
@import './component/timeline'; // components/TimeLineItem.js
@import './component/todo'; // components/ToDo.js
@import './component/toggle-btn'; // components/form/ToggleButton.js
@import './component/tooltip'; // Tooltip from 'reactstrap'
@import './component/topbar'; // components/topbar/*
@import './component/wizard'; // pages/form/wizard_form/*
@import './component/email-confirmation'; // pages/form/wizard_form/*
@import './component/material-table'; // pages/tables/material_table/*
@import './component/load';
@import './component/customizer';
@import './component/input.scss';
@import './component//banner.scss';
@import './component//Cards.scss';
@import './component/foooter.scss';
@import './component/StudentInformations.scss';
//pages...
@import 'containers/account'; // pages/account/* (except profile)
@import 'containers/cart'; // pages/e-commerce/cart/*
@import 'containers/dashboard'; // pages/dashboards/*
@import 'containers/invoice'; // pages/default_page/invoice_template
@import 'containers/not-found'; // pages/default_page/404/*
@import 'containers/payment'; // pages/e-commerce/payment/*
@import 'containers/product-card'; // pages/e-commerce/product-page/components/ProductCard.js
@import 'containers/product-edit'; // pages/e-commerce/product_edit/*
@import 'containers/products-list'; // pages/e-commerce/product_list/*
@import 'containers/profile'; // pages/account/profile/*
@import 'containers/project-summary'; // pages/default_pages/project_summary/*
@import 'containers/documentation';
@import 'containers/landing';
@import 'containers/featured';
@import 'containers/interests';
@import 'containers/activies';
//objects...
@import 'objects/layout'; // layout settings




.datepicker-custom{
    width: 100%;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    border-radius: 4px;
    border-color: #cacaca7a;
}
.react-datepicker{
    z-index: 999999 i !important;
}
.MuiListItemIcon-root{
    min-width: 26px !important;
}
.MuiListItem-gutters {
    padding-left: 0 !important;
}
.MuiListItem-root{
    padding-bottom: 0 !important;
}
// .MuiList-root.p-0.MuiList-padding .flex.my-10 {
//     margin-top: 0 !important;
// }
.login-form .material-icons.MuiIcon-root {
    color: #595959;
}
.MuiListItemSecondaryAction-root {
    right: 0px !important; 
}
.login-form .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    position: absolute;
    right: -46px;
    top: 5px;
}
.label-new-box {
    width: 100%;
}
.label-new-box span {
    font-size: 11px;
    max-width: 90px;
    background: white;
    text-align: center;
    margin: 0 8px;
    position: absolute;
    top: -9px;
    z-index: 1;
    padding: 0 5px;
}
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary {
    padding: 0 9px;
}
.sc-bHwgHz.fUokqI {
    background: none;
}
.material-icons.MuiIcon-root.event {
    position: absolute;
    margin: 8px;
    z-index: 1;
}
.list-icon-item{
    position: absolute;
}
.MuiListItem-root.MuiListItem-gutters.MuiListItem-secondaryAction {
    display: block;
    padding: 0;
}

.MuiListItem-root.MuiListItem-gutters.MuiListItem-secondaryAction input {
    border-radius: 5px;
    padding: 18px 31px;
    border-color: #c4c4c4;
}
.sc-bbmXgH.kDFSwf input {
    border-radius: 5px;
    border-color: #c4c4c4;
    padding: 17px 34px;
    margin: 5px;
    text-transform: lowercase;
}
.sc-bbmXgH.kDFSwf input:hover {
    border-radius: 5px;
    border-color: #000;
}
.MuiListItem-root.MuiListItem-gutters.MuiListItem-secondaryAction input:hover {

    border-color: #000;
}
.event-calender-icon{
    position: absolute;
    top: 34px;
    z-index: 1;
    left: 8px;
}
.sc-krvtoX.blzpGb {
    display: none;
}
.textfiled_input_date input{
    height: 34px !important;
    font-size: 12px;
}
.flex-middle .card-shadow.MuiPaper-elevation1 .makeStyles-content-57.pt-0.pb-0{
    max-height: 12rem !important;
}
.full_width .makeStyles-root-61{
    width: 21rem;
    max-width: 21rem;
}
.MuiCardContent-root .flex-middle .font-size-18{
    font-size: 14px !important;
}
.MuiCardContent-root .flex-middle .font-size-20 {
    font-size: 14px !important;
}
.textfiled_input .form__form-group{
    border: 1px solid lightgray;
    border-radius: 6px;
    height: 38px;
    margin-top: 7px;
}
.textfiled_input .MuiInput-underline{
    border: 0 !important;
}
.textfiled_input .MuiInput-underline::before {
    border: 0 !important;
}
.textfiled_input{
    text-align: left;
}
.textfiled_input .MuiInputBase-input{
    padding-left: 14px;
    height: 29px;
}
.textfiled_input .textfiled_input_label{
    position: absolute;
    top: 0;
    background: white;
    display: inline-block;
    z-index: 1025;
    left: 24px;
    padding: 0 5px;
    font-size: 11px;
color: gray;
}
.textfiled_input .MuiInput-underline::after {
    border-bottom: 0 !important;
}
.form__form-group{
    margin-top: 11px;
}