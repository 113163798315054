p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 0;
    @include themify($themes) {
        color: themed('colorText');
    }
}

p {
    margin: 10px 0 0 0;
    &:first-child {
        margin: 0;
    }
}

h1 {
    font-size: 36px;
    line-height: 48px;
    &.subhead {
        font-size: 30px;
        color: $color-additional;
        line-height: 36px;
    }
}

h2 {
    font-size: 30px;
    line-height: 36px;
    &.subhead {
        font-size: 24px;
        color: $color-additional;
        line-height: 32px;
    }
}

h3 {
    font-size: 24px;
    line-height: 32px;
    &.subhead {
        font-size: 18px;
        color: $color-additional;
        line-height: 24px;
    }
}

h4 {
    font-size: 18px;
    line-height: 24px;
    &.subhead {
        font-size: 12px;
        color: $color-additional;
        line-height: 16px;
    }
}

h5 {
    font-size: 14px;
    line-height: 18px;
    &.subhead {
        font-size: 10px;
        color: $color-additional;
        line-height: 12px;
    }
}

h6 {
    font-size: 12px;
    line-height: 16px;
    &.subhead {
        font-size: 8px;
        color: $color-additional;
        line-height: 10px;
    }
}

.bold-text {
    @include directify($directions) {
        text-align: directed('left');
    }
    font-weight: 700 !important;
}

.text-modal {
    font-weight: 700;
}

.typography--inline {
    display: flex;
    flex-wrap: wrap;
    * {
        margin-top: auto;
        @include directify($directions) {
            #{directed('margin-right')}: 15px;
        }
    }
}

.typography-card {
    @include directify($directions) {
        text-align: directed('left');
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 10px;
    }
}

blockquote {
    font-size: 13px;
    font-style: italic;
    @include directify($directions) {
        #{directed('border-left')}: 3px solid $color-accent;
    }
    margin-bottom: 0;
    @include directify($directions) {
        text-align: directed('left');
        #{directed('padding-left')}: 10px;
    }
}

.highlight {
    background-color: $color-accent;
    color: #ffffff;
}

.typography-message {
    @include directify($directions) {
        text-align: directed('left');
    }
}

.red-text {
    color: #ad4444 !important;
}
.text-success{
    color: green !important;
}

.page-title {
    @include directify($directions) {
        text-align: directed('left');
    }
    font-weight: 500;
    text-transform: capitalize;
    font-size: 20px;
    &:last-child {
        margin-bottom: 30px;
    }
    &.page-title--not-last {
        margin-top: 20px;
    }
}

.page-subhead {
    @include directify($directions) {
        text-align: directed('left');
    }
    margin-bottom: 20px;
    &.subhead {
        font-size: 14px;
        opacity: 0.7;
    }
}

::selection {
    color: #ffffff;
    background: $color-accent;
}

.text-primary {
    color: #ef3e37 !important;
}

.text-secondary {
    color: #ef3e37 !important;
}

.text-tranform-none {
    text-transform: none !important;
}

.text-left {
    text-align: left;
}

.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase !important;
}

.capitalize {
    text-transform: capitalize !important;
}


.text-nowrap {
    white-space: nowrap;
}

.text-black {
    color: black !important;
}
.font-size-10 {
    font-size: 10px !important;
}
.font-size-13 {
    font-size: 13px !important;
}
.font-size-15 {
    font-size: 15px !important;
}
.font-size-18 {
    font-size: 18px !important;
}
.font-size-20 {
    font-size: 20px !important;
}
.font-size-25 {
    font-size: 25px !important;
}
.font-size-30 {
    font-size: 30px !important;
}

.font-weight-700 {
    font-weight: 700;
}
.font-weight-600 {
    font-weight: 600;
}
.font-weight-500 {
    font-weight: 500;
}