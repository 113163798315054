.card-buttons {
    display: flex;
    background-color: #fff;
    margin-top: auto;
    position: sticky;
    bottom: 0;
    left: 0;
  
    Button {
      flex: 1 1 auto;
      user-select: none;
      background: 0;
      font-size: 13px;
      border: 0;
      padding: 15px 5px;
      cursor: pointer;
      color: #5c5c6d;
      transition: 0.3s;
      font-family: "Jost", sans-serif;
      font-weight: 500;
      outline: 0;
      border-bottom: 3px solid transparent;
  
      &.is-active,
      &:hover {
        color:  rgba(147, 46, 54, 1);
        
        border-bottom: 3px solid  rgba(147, 46, 54, 1);
        background: linear-gradient(
          to bottom,
          rgba(147, 46, 54, 0) 0%, 
          rgba(147, 46, 54, 0.1) 44%,
          rgba(147, 46, 54, 0.2) 100%
        );
      }
    }
  }

  .StudentModal{
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 1)

  }
  