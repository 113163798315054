.notification {
  max-width: 400px;
  width: calc(100% - 50px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  padding: 20px 40px 30px 25px;
  background: #ffffff;
  position: relative;
  margin: 10px 25px;

  &.notification--image {
    height: 106px;
    overflow: hidden;
  }

  &.notification--full-wide {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 20px 40px 20px 25px;

    .notification__message {
      text-align: center;
      width: calc(100% - 30px);
    }
  }

  &.notification--theme-light {
    background: #ffffff;
  }

  &.notification--theme-dark {
    background: #232329;
    border: 1px solid #33333a;
    color: #dddddd
  }

  &.notification--primary {
    background: $color-blue;

    .notification__message, .notification__title {
      color: #ffffff;
    }
  }

  &.notification--success {
    background: $color-accent;

    .notification__message, .notification__title {
      color: #ffffff;
    }
  }

  &.notification--warning {
    background: $color-yellow;

    .notification__message, .notification__title {
      color: #ffffff;
    }
  }

  &.notification--danger {
    background: $color-red;

    .notification__message, .notification__title {
      color: #ffffff;
    }
  }
}
.notification_drawer{
  width: 260px;
  & .notification__topbar{
    height: 64px;
    box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 6px 10px 0px rgba(0, 0, 0, 0.042),0px 1px 18px 0px rgba(0, 0, 0, 0.036) ;
  }
  .notification__card {
    &:hover {
      .delete-button {
        cursor: pointer;
        display: unset;
        right: 0;
        margin-top: 6px;
        top: 0;
        z-index: 2;
      }
      .card__topbar__time {
        display: none;
      }
    }
    .card__topbar {
    }

    .delete-button {
      display: none;
      position: absolute;
      right: 0;
      margin-top: 9px;
    }
    .card__topbar__button {
      height: 24px;
      width: 24px;
      border-radius: 15px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.9;
    }
  }
  .noti_read{
    background-color: #e6fde6 !important;
  }
}


.rc-notification-notice-close-x {
  color: $color-additional;
}

.notification__message {
  margin-top: 0;
  font-size: 12px;
}

.notification__title {
  margin-bottom: 8px;
}

.notification__image {
  position: absolute;
  height: 106px;
  width: 106px;
  top: 0;

   @include directify($directions) {
     #{directed('left')}: 0;
   }

  & ~ * {
     @include directify($directions) {
       #{directed('padding-left')}: 106px;
     }
  }
}

.sidebar.sidebar--collapse + .container__wrap {

  .notifications-tl, .notifications-bl {
    padding-left: 0 !important;
  }
}