.dwul__footer {
    // margin: 10px auto;
    text-align: center;
    padding: 10px;
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: #F2F4F7;
}

.dwul-img {
    width: 3rem;
}

.mis-solution-img {
    width: 5rem;
}