.Banner {
    // position: relative;
   
    background: url('../../shared/img/banner/about.jpg');
    padding-top: 10%;
    height: 40rem;
    background-size: cover;
    background-repeat: no-repeat;
}

.content {
    font-size: large;
    padding-top: 2em;
}

.content>p {
    text-align: center;
    justify-content: center;
    padding-right: 20px;
}

.half-half {
    // position: relative;
    transform: translateY(-50%);
    // margin-top: -2.3rem;
    // margin-top: 8rem;
    // padding-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    // z-index: 1;
}

.text {
    font-size: 1.5vw;
}

.avatar {
    // position: relative;
    // margin-top: -30px;
    // margin-left:10rem ;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    transform: translateY(-50%);
}

.upper-container {
    height: 50px;
    background: #7F00FF;
}

//   .light-yellow{
//       background-color: #ffff00;
//   }
.boxh {
    transition: box-shadow .3s;
}

.boxh:hover {
    box-shadow: 0 0 11px rgb(203, 74, 66);
}

.one {
    background: #20B2AA;
}

.three {
    background: #FF8A8A
}

.two {
    background: #FFA07A;
}

.back {
    background-color: #FF8A8A
}

// .half-half {
//   position: absolute;
//   margin-left: 10em;
//   z-index: 999999;
// }
// .about {
//     margin-top: 15px;
// }
// .about p{
//     text-align: center;
// }
// .counter p{
//     text-align: center;
//     margin-right: 30px;
//     margin-top: 10em;
// }
@media (max-width: 500px) {
    .text {
        font-size: 2vw;
    }
}

@media (max-width: 700px) {
    .text {
        font-size: 2vw;
    }
}