.float-right {
    float: right;
}

.float-left {
    float: left;
}

.flex {
    display: flex;
}

.flex-space-between {
    justify-content: space-between !important;
}
.flex-middle{
    align-items: center !important;

}
.flex-center{
    justify-content: center !important;
}
.flex-end{
    justify-content: flex-end ;
}

// boder styles and display 
.boder-bottom-unset {
    border-bottom: unset !important;
}

.flex-center {
    align-items: center;
}
.flex-space-evenly{
    justify-content: space-evenly !important;
}
.flex-space-around{
    justify-content: space-around !important;
}


// color background

.bg-light-grey{
    background-color: #596c7d21 !important;
}
.scroll {
    overflow: scroll;
}