// .registr{
//     display: grid;
//     grid-gap: 1px;
//     grid-template-columns: repeat(6, 1fr);
// }
// @media (max-width: 900px){
//     .registr {
//         grid-template-columns: repeat(3, 1fr);
//       }
//   }
.my-row{
    margin-top: 5px;
    border:5px solid white;
    align-items: center;
}
.my-col{
    margin: auto;
    
}