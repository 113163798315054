
.m-5 {
    margin: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-10 {
    margin-top: 10px ;
}
.mt-15 {
    margin-top: 15px ;
}

.ml-10 {
    margin-left: 10px !important;
}

.flex {
    display: flex;
}

.pr-8 {
    padding-right: 8px !important;
}

.pl-8 {
    padding-left: 8px !important;
}

.p-16 {
    padding: 16px !important;
}

$spaces: ( 20n: -20px, 24n: -24px, 28n: 28px, 30n: -30px, 36n: -36px, 16n: -16px, 12n: -12px, 10n: -10px, 8n: -8px, 4n: -4px, 0: 0px, 4: 4px, 8: 8px, 10: 10px, 12: 12px, 16: 16px, 20: 20px, 24: 24px, 28: 28px, 30: 30px, 32: 32px, 36: 36px, 40: 40px, 44: 44px, 48: 48px);
@each $name,
$value in $spaces {
    .m-#{$name} {
        margin: $value !important;
    }
    .mt-#{$name} {
        margin-top: $value !important;
    }
    .mr-#{$name} {
        margin-right: $value !important;
    }
    .mb-#{$name} {
        margin-bottom: $value !important;
    }
    .ml-#{$name} {
        margin-left: $value !important;
    }
    .mx-#{$name} {
        margin-left: $value !important;
        margin-right: $value !important;
    }
    .my-#{$name} {
        margin-top: $value !important;
        margin-bottom: $value !important;
    }
    .p-#{$name} {
        padding: $value !important;
    }
    .pt-#{$name} {
        padding-top: $value !important;
    }
    .pr-#{$name} {
        padding-right: $value !important;
    }
    .pb-#{$name} {
        padding-bottom: $value !important;
    }
    .pl-#{$name} {
        padding-left: $value !important;
    }
    .px-#{$name} {
        padding-left: $value !important;
        padding-right: $value !important;
    }
    .py-#{$name} {
        padding-top: $value !important;
        padding-bottom: $value !important;
    }
}

.m-auto {
    margin: auto !important;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mb-38 {
    margin-bottom: 38px;
}

.mb-14 {
    margin-bottom: 14px !important;
}

.mb-1 {
    margin-bottom: 1px;
}

.mb-2 {
    margin-bottom: 2px;
}

.ml-auto {
    margin-left: auto;
}

.ml--12 {
    margin-left: -12px;
}

.w-20{
    width: 20%;
}
.w-80{
    width: 80% !important;
}
.w-90 {
    width: 90% !important;
}

.w-100 {
    width: 100%;
}

.w-220 {
    width: 220px;
}

.h-10 {
    height: 10px !important;
}

.h-50 {
    height: 50% !important;
}

.h-55 {
    height: 55px !important;
}

.h-100 {
    height: 100% !important;
}

.h-150 {
    height: 150px !important;
}

.h-320 {
    height: 320px;
}

.h-100vh {
    height: 100vh;
}

.h-100vh-80 {
    height: calc(100vh - 80px);
}

.h-500 {
    height: 500px !important;
}

.h-700 {
    height: 700px;
}

.h-800 {
    height: 800px;
}

.h-48 {
    height: 48px;
}

.h-70 {
    height: 70px;
}

.h-32 {
    height: 32px;
}

.h-56 {
    height: 56px;
}
.h-auto {
    height: auto;
}
.size-36 {
    height: 36px !important;
    width: 36px !important;
}

.size-24 {
    height: 24px !important;
    width: 24px !important;
}

@media (max-width: 979px) {
    .sm-m-auto{
        margin: auto !important;
    }
}
@media (max-width: 1200px) {
    .md-m-auto{
        margin: auto !important;
    }
}